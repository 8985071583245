@import "../animation/animation.scss";
$selected-font-color: #85364dff;
$complementary-font-color: #36856E;
$monochromatic-font-color: #A94562;


.slide {
    width: 100%;
    height: 100%;
    position: relative;
  }
  
  .slide-hidden {
    display: none;
  }

.slideImage {
    border-radius: 0.5rem;
    box-shadow: 0px 0px 7px #666;
    width: 35%;
    height: 65%;
    position: relative;
    right:12.5%;
    top: 10%;
    overflow:hidden;
    -webkit-animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


  
  .arrow {
    position: absolute;
    filter: drop-shadow(0px 0px 5px #555);
    width: 2rem;
    height: 2rem;
    color: #A94562;
    z-index: 1;
  }
  
  .arrow:hover {
    cursor: pointer;
    color: #85364dff;
  }
  
  .arrow-left {
    left: 2rem;
  }
  
  .arrow-right {
    right: 2rem;
  }

  .textContainer {
    position: absolute;
    bottom: 20%;
    width: 35%;
    height: 55%;
    background-color: #A34757;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    box-shadow: 0px 0px 7px #555;
    left: 50%;
    border-radius: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    -webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    .slideTitle {
      color: white;
      text-align: left;
      padding-right: 0.5rem;
    }
    .slideLine {
      background-color: white;
      height: 0.1rem;
      width: 100%;
      margin-bottom: 0.5rem;
    }
    .slideText {
      color: white;
      text-align: justify;
      font-size: medium;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
    .slideSpecialText {
      font-size: 300%;
      padding-bottom: 1rem;
      font-weight: bold;
      color: white;
      justify-content: center;
      display: flex;
      text-align: center;
    }
  }
  
  .indicators {
    display: flex;
    position: absolute;
    bottom: 3rem;
  }
  
  .indicator {
    background-color: #85364dff;
    height: 0.5rem;
    width: 5rem;
    border-radius: 10px 100px / 120px;
    border: 1px solid black;
    outline: none;
    box-shadow: 0px 0px 5px #555;
    margin: 0 0.2rem;
    cursor: pointer;
  }

  
  .indicator-inactive {
    background-color: grey;
    &:hover {
      background-color: #BF627D;
    }
  }
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
html {
  min-height: 100%;
  position: relative;
}
body {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 350;
  font-style: normal;
  padding-top: 150px;
  margin-bottom: 30px;
  min-height: 200px;
  background-color: whitesmoke;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

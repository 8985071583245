$selected-font-color: #85364dff;
$complementary-font-color: #36856E;
$monochromatic-font-color: #A94562;

.navHeader {
    background-color: #FFFFFF;
    top: 0;
    left: 0;
    padding: 0;
    position: fixed;
    width: 100%;
    z-index: 2;
    border-bottom: 1px solid #E0E0E0;
}

.-sticky {
    padding-bottom: 10px;
    padding-top: 10px;
    .logoImg {
        display: flex;
        flex: 0 0 auto;
        width: 20%;
        height: 50px;
    }
    .navbar {
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        width: 80%;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        margin-left: auto;
        margin-right: auto;
    }
}

.navbar {
    font-weight: 500;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.menu__list {
    $underline-width: 0;
    $underline-offset-x: 0;
    position: relative;
    margin: 0 auto;
    display: inline-flex;
    color: $selected-font-color;
    list-style-type: none;
    padding-left: 0;
}

.menu__item {
    opacity: 0.7;
    display: inline-block;
    position: relative;
    padding: 20px 0px;
    margin: 0px 20px;
    color: $selected-font-color;
    text-decoration: none;
    text-transform: uppercase;
    &::before {
        position: absolute;
        height: 0.35rem;
        background-color: $monochromatic-font-color;
        bottom: 0.5rem;
        content: '';
    }
    &:hover {
        &::before {
            width: 100%;
        }
        opacity: 0.85;
    }
    &.active {
        opacity: 1;
    }
  }

.logoImg {
    display: flex;
    flex: 0 0 auto;
    justify-content: center;
    align-items: center;
}

.logoImg img {
    height: 75px;
    margin: 0 auto;
}


$selected-font-color: #85364dff;
$complementary-font-color: #36856E;
$monochromatic-font-color: #A94562;

.footer {
    background-color: $selected-font-color;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2.5rem;
    left: 0;
}

p {
    margin: 0;
    text-align: center;
    padding: 0;
    font-size: 1rem;
    line-height: 2.5rem;
    color: white;
}

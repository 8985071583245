$selected-font-color: #85364dff;
$complementary-font-color: #36856E;
$monochromatic-font-color: #A94562;

.App {
  text-align: center;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.title {
  margin-top: 10px;
  margin-bottom: 10px;
  
  b {
    color: $selected-font-color;
  }
}

.carousel {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  margin: 0 auto;
}

.pageLinks {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  height: auto;
  width: 100%;
}

.pageLink {
  padding-top: 20px;
  padding-bottom: 20px;
  display: block;
  > * { // I LOVE NESTED SELECTORS WOOOOOOO
    > * {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
  &.__products {
    right: 0;
    width: 50%;
    color: white;
    background-color: #85364dff;
    padding-right: 5%;
    text-align: right;

  }
  &.__services {
    left: 0;
    width: 50%;
    color: white;
    background-color: #36856E;
    padding-left: 5%;
    text-align: left;
  }
}

.text {
  width: 25%;
  height: 100%;
  margin-bottom: auto;
  &.__products {
    margin-left: auto;
  }
}

h2 {
  margin: 0 0;
}

.button {
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  box-sizing: border-box;
  color: #121212;
  display: inline-flex;
  flex: 1 1 auto;
  font-weight: 550;
  font-size: 16px;
  justify-content: center;
  line-height: 1;
  margin: 0;
  outline: none;
  padding: 0.75rem 0.5rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s,-webkit-box-shadow .2s;
  white-space: nowrap;
  border: 0;

  &.__products:hover {
      box-shadow: #A94562 0 0 0 3px, transparent 0 0 0 0;
      color: white;
      background-color: #6c424e;
  }

  &.__services:hover {
      box-shadow: #45A98C 0 0 0 3px, transparent 0 0 0 0;
      color: white;
      background-color: #2d4d3f;
  }
}

.about {
  justify-content: center;
  line-height: 120%;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
  margin: 0 auto;
  color: black;
  top: 50%;
  b {
    color: $selected-font-color;
  }
}